<template>
  <div style="padding-top:60px;">
    <organ-map />
    <div class=" certification-index-content">
      <div class="certification-index-content-title">
        <p>PERMANENT ESTABLISHMENT</p>
        <p>常设机构</p>
      </div>
      <div class="certification-index-content-box">
        <div v-for="(item, index) in list"
             :key="index"
             class="certification-index-content-item pointer">
          <div class="certification-index-content-item-t">
            <div>
              <span style="font-weight: 500;">{{ item.name }}</span>
              <span class="iconfont iconanquan1"
                    style="color:#4B9CFC;margin-left:10px"></span>
            </div>
            <div :style="'background:' + colorList[index?1:0]">
              {{ item.name.substring(0, 4) }}
            </div>
          </div>
          <div class="certification-index-content-item-b"
               style="margin-top: 18px;">
            <div class="top">
              <div class="title">联系电话：</div>
              <div class="value">{{ item.phone }}</div>
            </div>
            <div class="bottom">
              <div class="title">地址：</div>
              <div class="value">{{ item.adress }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import list from "./company.list";
import organMap from "./organMap";
export default {
  components: {
    organMap
  },
  data () {
    return {
      colorList: ["#DF2A29", "#2990F9", "#FCC12D"],
      list
    };
  }
};
</script>
<style lang="scss" scoped>
.certification-index-content {
  width: 1240px;
  margin: 0 auto;
  margin-bottom: 30px;
  &-title {
    height: 160px;
    text-align: center;
    position: relative;
  }
  p:first-child {
    font-size: 28px;
    color: rgba(51, 51, 51, 0.2);
    position: absolute;
    top: 70px;
    left: 0px;
    right: 0px;
    margin: auto;
    font-weight: 500;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  }
  p:last-child {
    color: #333333;
    font-weight: 500;
    font-size: 28px;
    position: absolute;
    top: 85px;
    left: 0px;
    right: 0px;
    margin: auto;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  }
  &-box {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
  &-item {
    width: 380px;
    height: 184px;
    margin-bottom: 40px;
    background: url(../../static/certificationImg/bg_2.png) no-repeat center
      center;
    background-size: 100% 100%;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 30px 30px 10px 30px;
    box-sizing: border-box;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;

    &-t {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      div:first-child {
        width: 260px;
        font-size: 16px;
        line-height: 20px;
      }
      div:last-child {
        width: 54px;
        height: 54px;
        background: #2990f9;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        color: #fff;
        box-sizing: border-box;
        font-size: 20px;
        text-align: center;
        display: flex;
        align-items: center;
      }
    }
    &-b {
      line-height: 20px;
      color: #333333;
      font-size: 14px;
      text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
      .top,
      .bottom {
        display: flex;
      }
      .top {
        margin-top: 22px;
        .title {
          width: 70px;
        }
      }
      .bottom {
        margin-top: 10px;
        .title {
          width: 42px;
        }
        .value {
          width: 270px;
        }
      }
    }
  }
  &-item:nth-child(3n-1) {
    margin-left: 50px;
    margin-right: 50px;
  }
}
</style>