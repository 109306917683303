export default [
	{
		"name": "网红头条总部",
		"adress": "洛阳市涧西区万达世纪中心21层",
		"phone": "17703887474"
	},
	{
		"name": "网红头条（沈阳）运营中心",
		"adress": "沈阳市和平区十四纬路51号房实大厦14楼",
		"phone": "17696613688"
	},
	{
		"name": "网红头条（重庆）运营中心",
		"adress": "重庆市江北区观音桥未来国际24层",
		"phone": "18228651292"
	},
	// {
	// 	"name": "网红头条（朝阳）运营中心",
	// 	"adress": "辽宁省朝阳市龙城区新天地文化城6号馆二层  ",
	// 	"phone":"17174468888"
	// },
	{
		"name": "网红头条（嘉兴）运营中心",
		"adress": "浙江省嘉兴市南湖区新文化广场1号楼",
		"phone": "18049886661"
	},
	{
		"name": "网红头条（衢州）运营中心",
		"adress": "浙江省衢州市江山市峰景大厦9层",
		"phone": "15868230999"
	},
	{
		"name": "网红头条（郑州）运营中心",
		"adress": "郑州市郑东新区平安大道永和龙子湖广场B座26层",
		"phone": "13838641319"
	},
]
