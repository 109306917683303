/* 
 * @Author: 曹俊杰 
 * @Date: 2021-03-23 14:42:23
 * @Module: 常设机构地图
 */
 <template>
  <div class="organMap">
    <div class="organMap-box">
      <div class="organMap-box-l">
        <p class="iconfont iconicon-test"></p>
        <p class="title">网红头条常设机构</p>
        <p>总部联系电话</p>
        <p>
          <span class="iconfont icondianhua"></span>
          <span>400-119-3009</span>
        </p>
        <p class="title">咨询时间</p>
        <p>周一至周六 12:00～22:00</p>
        <img class="code"
             src="@/static/organ/adminCode.png">
        <p>扫描二维码，加入我们</p>
      </div>
      <div class="organMap-box-r">
        <organ-map-echart />
      </div>
    </div>
  </div>
</template>
 <script>
import organMapEchart from "./organMapEchart"
export default {
  components: {
    organMapEchart
  },
  data () {
    return {};
  },
  mounted () { },
  methods: {},
};
 </script>
 <style lang='scss' scoped>
.organMap {
  height: 760px;
  width: 100%;
  background: url(../../static/organ/image.png) no-repeat center center;
  background-size: auto 100%;
  &-box {
    height: 100%;
    width: 1240px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    &-l {
      width: 392px;
      height: 100%;
      background: #ffffff;
      box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.16);
      color: #262626;
      padding-top: 70px;
      padding-left: 40px;
      .code {
        width: 260px;
        height: 260px;
        background: #eeeeee;
        border-radius: 4px;
        margin-top: 18px;
      }
      .title {
        font-weight: 600;
        line-height: 40px;
        font-size: 28px;
      }
      p:nth-child(2) {
        margin-top: 18px;
      }
      p:nth-child(3) {
        margin-top: 18px;
        line-height: 25px;
        font-size: 18px;
      }
      p:nth-child(4) {
        margin-top: 18px;
        line-height: 25px;
        font-size: 18px;
        span:nth-child(2) {
          margin-left: 6px;
        }
      }
      p:nth-child(5) {
        margin-top: 26px;
      }
      p:nth-child(6) {
        margin-top: 18px;
        line-height: 25px;
        font-size: 18px;
      }
      p:nth-child(8) {
        margin-top: 18px;
        line-height: 25px;
        font-size: 18px;
      }
    }
    &-r {
      width: 760px;
      height: 100%;
    }
  }
}
</style>